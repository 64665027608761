@use '@angular/cdk' as cdk;
@import './bootstrap.custom.scss';
@include cdk.overlay();
@import 'theme-color';

html,
body {
  height: 100%;
  background-color: white;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}
table.table-design-rounded {
  border-collapse: separate;
  border-spacing: 0px;

  tr,
  td,
  th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-top: none;
    vertical-align: middle;
  }
  td:last-child {
    overflow: unset;
  }
  tbody {
    tr {
      &:first-child {
        border-top: 1px solid #dee2e6 !important;
      }
      td {
        background-color: $white;
        border-bottom: 1px solid #dee2e6 !important;

        &:first-child {
          min-width: fit-content;
          border-left: 1px solid #dee2e6 !important;
        }
        &:last-child {
          min-width: fit-content;
          border-right: 1px solid #dee2e6 !important;
        }
      }
    }
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 24px;
      }
      td:last-child {
        border-bottom-right-radius: 24px;
      }
    }
  }
  table-layout: fixed;
  .table-header {
    background-color: #d4e4e3;
    th:last-child {
      border-top-right-radius: 24px;
      border: 0 !important;
      width: 7rem;
    }
    th:first-child {
      border-top-left-radius: 24px;
      border: 0 !important;
      width: 7rem;
    }
  }

  .dropdown {
    color: black;
    button:hover {
      background-color: black;
      color: white;
    }
  }
}
formly-form {
  .ng-invalid.ng-touched {
    border-color: #e94545;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e94545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e94545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  .btn-outline-primary:hover {
    color: white !important;
  }
  option:disabled {
    color: gray !important;
  }
  select:invalid {
    color: gray !important;
  }
  .form-check-input {
    accent-color: $dark-blue;
  }

  .form-check-container {
    border-radius: 20px !important;
  }

  input[type='text'].form-control,
  input[type='number'].form-control {
    border-radius: 25px !important;
    min-height: 3.3rem;
    font-size: 95%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  select {
    background: url(assets/icons/angle-down-solid.svg) no-repeat right white;
    -webkit-appearance: none;
    background-position-x: 96%;
    background-size: 12px;
    border-radius: 25px !important;
    min-height: 3.3rem;
    font-size: 95%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding: 0.375rem 2rem !important;
    font-size: 95% !important;
    border-radius: 20px !important;
  }
  textarea.form-control {
    border-radius: 20px !important;
    font-size: 95%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    resize: none;
  }

  label {
    text-transform: uppercase;
    color: #6c757d;
    font-size: small;
    font-weight: bold;
  }
  .form-group {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}
.smooth-border {
  border-radius: 12px !important;
}
.soft-border {
  border-radius: 8px !important;
}
.rounded-border {
  border-radius: 100px !important;
}
.top-rounded {
  border-radius: 0.25rem 0.25rem 0 0;
}
.border-none {
  border-radius: 0;
}
.bottom-rounded {
  border-radius: 0 0 0.25rem 0.25rem;
}
.pos-relative {
  position: relative !important;
}
.pos-absolute {
  position: absolute !important;
}
.pos-flex {
  position: flex !important;
}
.hidden {
  display: none;
}
.visibility-hidden {
  visibility: hidden;
}
.app-page-container {
  overflow: hidden;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

li:focus {
  outline: none;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.ng-scroll-content {
  height: 100%;
}

.center {
  text-align: center;
}

h1,
h2,
h3,
h4 {
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.3rem;
  font-weight: 600;
}

h4 {
  font-size: 1.1rem;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
app-displayed-projects {
  width: 100%;
}
.page,
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
}

.scrollable {
  margin-right: 0 !important;
  overflow-x: hidden;
  overflow-y: auto;
}
.spacer {
  height: 30px;
}
.hidden-on-mobile {
  display: none;
}
.only-on-mobile {
  display: block;
}
// .hidden-on-tablet {
//   display: block;
// }
// .only-on-tablet-portrait {
//   display: none;
// }
// .only-on-tablet-landscape {
//   display: none;
// }
@media screen and (min-width: 480px) {
  .hidden-on-mobile {
    display: block;
  }
  .only-on-mobile {
    display: none;
  }
}
// @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
//   .only-on-tablet-portrait {
//     display: block;
//   }
//   .hidden-on-tablet {
//     display: none !important;
//   }
// }

// @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
//   .only-on-tablet-landscape {
//     display: block;
//   }
//   .hidden-on-tablet {
//     display: none !important;
//   }
// }

// Bootstrap Modal

// .modal-dialog {
//   max-width: 1000px;
// }

.cdk-overlay-container {
  z-index: 1051;
}

.smallSpinner {
  width: 16px !important;
  height: 16px !important;
  border-width: 0.17em;
  animation-duration: 2.75s;
}
.hover-active {
  &:hover {
    color: $theme-active-menu !important;
  }
}
.form-control {
  textarea {
    border-bottom: none;
    color: $blue;
  }
  textarea:focus {
    border-bottom: none;
  }
}
.btn.disabled,
.btn:disabled {
  opacity: 0.35 !important;
  cursor: not-allowed;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: $theme-active-menu;
  outline: 0;
  box-shadow: 0 0 0 0.2rem $theme-active-menu-transparent;
}

.workflow-node-drawer {
  > div {
    position: absolute;
    right: 0;
    margin: 0;
    height: calc(100vh - 75px) !important;
    top: 75px;
    .modal-content {
      height: calc(100vh - 75px);
      overflow-y: hidden;
    }
  }
}

.litegraph-editor {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--solution-editor-background);
  color: #eee;
  font-size: 14px;
  position: relative;
}

@import '../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css';
